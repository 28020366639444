import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Span, List, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ev | Lux Vorux'a Hoş Geldiniz
			</title>
			<meta name={"description"} content={"Lux Vorux'ta, spor salonumuza yapılan her ziyaretin kişisel bir zafer olduğuna inanıyoruz."} />
			<meta property={"og:title"} content={"Ev | Lux Vorux'a Hoş Geldiniz"} />
			<meta property={"og:description"} content={"Lux Vorux'ta, spor salonumuza yapılan her ziyaretin kişisel bir zafer olduğuna inanıyoruz."} />
			<meta property={"og:image"} content={"https://luxvorux.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://luxvorux.com/img/9034254.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://luxvorux.com/img/9034254.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://luxvorux.com/img/9034254.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://luxvorux.com/img/9034254.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://luxvorux.com/img/9034254.png"} />
			<meta name={"msapplication-TileImage"} content={"https://luxvorux.com/img/9034254.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(77, 77, 77, 0.6) 0%,rgba(77, 77, 77, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://luxvorux.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="--lead"
				letter-spacing="1px"
			>
				Fitness Yolculuğunuza Hoş Geldiniz!
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="normal 700 68px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="--secondary"
				padding="0px 50px 0px 50px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				Lux Vorux'a
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 250px 0px 250px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Lux Vorux'ta, spor salonumuza yapılan her ziyaretin kişisel bir zafer olduğuna inanıyoruz. İster yeni başlayan ister deneyimli bir sporcu olun, sıcak ortamımız ve son teknoloji tesislerimiz fitness hedeflerinize ulaşmanıza yardımcı olmak için tasarlanmıştır. Destek ve mücadelenin buluştuğu, sizi en iyi halinize doğru yönlendiren bir topluluğun içine dalın.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Neden Lux Vorux'u Seçmelisiniz?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Topluluk Ruhu
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Spor salonumuz sadece egzersiz yapılan bir yer değil, bir topluluktur. Fitness tutkunu arkadaşlarınızla bağlantı kurun, yolculuğunuzu paylaşın ve yolun her adımında teşvik bulun.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Kişiselleştirilmiş Antrenman Planları
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Bireysel ihtiyaçlarınıza göre uyarlanmış çeşitli fitness programları ile uzman eğitmenlerimiz sağlıklı yaşam için doğru yolda olmanızı sağlar.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Hile Yapmadan Modern Ekipmanlar
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Yüksek kaliteli, basit fitness ekipmanlarına erişimin keyfini çıkarın. Üst düzey teknoloji yok, sadece mükemmelleşmenize yardımcı olacak etkili araçlar var.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					İletişime Geçin
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" color="--greyD2" font="18px/27px --fontFamily-sans">
							
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								İlk adımı atmaya hazır mısınız? Yeni fitness eviniz Lux Vorux'ta bizi ziyaret edin. Daha fazla bilgi almak, tur rezervasyonu yaptırmak veya topluluğumuza katılmak için bizimle iletişime geçin.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image src="https://luxvorux.com/img/2.jpg" display="block" border-radius="32px" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});